import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { ROOM_DATA } from "@setups/data";
import updateTaskAttributes from "@app/ng/tasks/services/helpers/updateTaskAttributes";
import { DrStore } from "@app/vue";
import { $notifyDanger, createDictionary, dowloadFile } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories/categories";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import { TasksApiService } from "./api";
import { loadTaskCommentInput, storeTaskCommentInput } from "./utils";

import type { TaskLabel } from "../tasksLabels/tasksLabelApi";
import type {
  CreateTaskPayload,
  ParticipantUserV1,
  Task,
  TaskPriority,
  UserUid,
} from "./api";
import type {
  ITaskCommentDraft,
  TaskComment,
} from "@drVue/store/modules/room/types";
import type { Category } from "@drVue/store/pinia/room/categories/api";
import type { PartiallyRequired } from "@drVue/types";

export type { TasksImportResult } from "./api";

const api = new TasksApiService();

export const useTasksStore = defineStore("tasks", () => {
  const tasksArchivedStore = useTasksArchivedStore(pinia);
  const findingsStore = useFindingsStore(pinia);
  const tasksLabelsStore = useTasksLabelsStore(pinia);
  const categoriesStore = useCategoriesStore(pinia);

  const loadingPromise = ref<Promise<Task[]> | null>(null);

  const tasksList = ref<Task[]>([]);

  const tasks = computed(() => {
    return Object.freeze(
      tasksList.value.reduce((dict, task) => {
        dict[task.id] = task;
        return dict;
      }, createDictionary<Task>()),
    );
  });

  const tasksByUid = computed(() => {
    return tasksList.value.reduce((dict, task) => {
      dict[task.uid] = task;
      return dict;
    }, createDictionary<Task>());
  });

  const tasksByCategoryId = computed(() => {
    const tasksByCategoryMap = tasksList.value.reduce((dict, task) => {
      if (!dict[task.category_id]) dict[task.category_id] = [];
      dict[task.category_id].push(task);
      return dict;
    }, createDictionary<Task[]>());

    Object.keys(tasksByCategoryMap).forEach((categoryId) => {
      tasksByCategoryMap[categoryId].sort((a, b) => a.order - b.order);
    });

    return Object.freeze(tasksByCategoryMap);
  });

  const dependantsMap = computed(() => {
    return Object.freeze(
      tasksList.value.reduce((dict, task) => {
        for (const depId of task.dependencies) {
          if (!dict[depId]) dict[depId] = [];
          dict[depId].push(task);
        }
        return dict;
      }, createDictionary<Task[]>()),
    );
  });

  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const load = (skipErrorAlert: boolean = true) => {
    if (isLoading.value) return loadingPromise.value;

    isLoading.value = true;
    isError.value = false;

    loadingPromise.value = api
      .loadTasks()
      .then(
        (_tasksList) => {
          _tasksList.forEach((task) => updateTaskAttributes(task));

          DrStore.dispatch("room/tasksRelated/update", _tasksList);

          tasksList.value = _tasksList;

          return _tasksList;
        },
        (error) => {
          isError.value = true;

          if (!skipErrorAlert) {
            $notifyDanger("Failed to update requests.");
          }

          return Promise.reject(error);
        },
      )
      .finally(() => (isLoading.value = false));

    return loadingPromise.value;
  };

  const loadTaskDetails = (key: Task["key"]) => {
    return api.loadTaskByKey(key).then((task) => {
      if (tasks.value[task.id]) {
        setTask(task);
      } else {
        updateTaskAttributes(task);
        tasksList.value.push(task);
      }

      DrStore.dispatch("room/tasksRelated/update", tasksList.value);

      return task;
    });
  };

  const setTask = (changes: Partial<Task>) => {
    const i = tasksList.value.findIndex((t) => t.id === changes.id);
    if (i === -1) return;

    tasksList.value[i] = {
      ...tasksList.value[i],
      ...changes,
    };

    updateTaskAttributes(tasksList.value[i]);
    delete (tasksList.value[i] as any).$$hashKey; // force angular to treat the object as changed
  };

  const createTaskV2 = (task: CreateTaskPayload) => {
    return api.createTaskV2(task).then((createdTask) => {
      updateTaskAttributes(createdTask);
      tasksList.value.push(createdTask);

      DrStore.dispatch("room/tasksRelated/update", tasksList.value);

      return createdTask;
    });
  };

  const deleteTask = (taskId: number) => {
    const i = tasksList.value.findIndex((t) => t.id === taskId);
    if (i === -1) return;

    tasksList.value.splice(i, 1);
  };

  const deleteTaskV2 = async (taskId: number) => {
    await api.deleteTaskV2(taskId);
    return reloadTasks();
  };

  const deleteTasksV2 = async (taskIds: string[]) => {
    await api.deleteTasksV2(taskIds);
    return reloadTasks();
  };

  const restoreTasksV2 = async (taskIds: string[]) => {
    const restoredTasks = await api.restoreTasksV2(taskIds);
    return reloadTasks().then(() => restoredTasks);
  };

  const reloadTasks = () => {
    const updatePromises = [load()];
    if (ROOM_DATA.userPermissions.administrator) {
      updatePromises.push(tasksArchivedStore.load());
    }

    return Promise.all(updatePromises);
  };

  const reloadTaskById = (taskId: Task["id"]) => {
    return api.loadTaskV2(taskId).then((task) => {
      setTask(task);
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);

      return task;
    });
  };

  const patchTaskV2 = (taskId: string, changes: Partial<Task>) => {
    return api.patchTaskV2(taskId, changes).then((t) => {
      setTask(t);
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);

      return t;
    });
  };

  const patchTaskBulkV2 = (tasks: PartiallyRequired<Task, "id">[]) => {
    return api.patchTaskBulkV2(tasks).then(async (updatedTasks) => {
      updatedTasks.forEach(setTask);
      await DrStore.dispatch("room/tasksRelated/update", tasksList.value);
    });
  };

  const deleteTasksLabel = (labelId: number) => {
    for (const task of tasksList.value) {
      const i = task.labels.indexOf(labelId);
      if (i > -1) task.labels.splice(i, 1);
    }
  };

  const setTaskCommentInput = (taskId: number, text: object): void =>
    storeTaskCommentInput(taskId, text);

  const getTaskCommentInput = (taskId: number) => loadTaskCommentInput(taskId);

  const moveTaskToTop = (taskId: string) => {
    return api
      .patchTaskV2(taskId, { order: 0 })
      .then(() => {
        load();
      })
      .catch(() => {
        $notifyDanger("Something went wrong while moving a task to top");
      });
  };

  const moveTask = (
    taskId: string,
    localOrder: number,
    categoryId?: number,
  ) => {
    const changes: Partial<Task> = {
      order: localOrder,
      category_id: categoryId,
    };
    if (!categoryId) delete changes.category_id;

    return api
      .patchTaskV2(taskId, changes)
      .then(() => {
        load();
      })
      .catch(() => {
        $notifyDanger("Something went wrong while moving a task");
      });
  };

  const addComment = (
    taskId: Task["id"],
    comment: ITaskCommentDraft,
  ): Promise<TaskComment> => {
    return api.addComment(taskId, comment).then((comment) => {
      const task = tasks.value[comment.task_id];
      setTask({ ...task, comments_count: task.comments_count + 1 });
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);
      return comment;
    });
  };

  const updateTaskLastVisitDate = async (taskId: Task["id"]): Promise<Task> => {
    const sourceTask = tasks.value[taskId];
    if (sourceTask.is_archived && !ROOM_DATA.userPermissions.administrator) {
      // non-admin can't access archived tasks
      // this method is called if task is archived from modal
      return sourceTask;
    }

    const result = await api.updateTaskLastVisitDate(taskId);
    setTask({ ...sourceTask, ...result, new_comments_count: 0 });

    return tasks.value[taskId];
  };

  const updateDependencies = async (
    taskId: number,
    add: { task_id: Task["id"] }[] = [],
    remove: { task_id: Task["id"] }[] = [],
  ): Promise<void> => {
    const updatedTask = await api.updateDependencies(taskId, add, remove);
    setTask(updatedTask);
  };

  const updateAssignees = async (
    taskId: number,
    add: { user_id: string }[],
    remove: { user_id: string }[],
  ): Promise<void> => {
    const updateTask = await api.updateAssignees(taskId, add, remove);
    setTask(updateTask);
  };

  const updateReviewers = async (
    taskId: number,
    add: { user_id: string }[],
    remove: { user_id: string }[],
  ): Promise<void> => {
    const updateTask = await api.updateReviewers(taskId, add, remove);
    setTask(updateTask);
  };

  const updateFollowers = async (
    taskId: number,
    add: { user_id: string }[],
    remove: { user_id: string }[],
  ): Promise<void> => {
    const updateTask = await api.updateFollowers(taskId, add, remove);
    setTask(updateTask);
  };

  const updateDocuments = async (
    taskId: number,
    add: { document_id: string }[],
    remove: { document_id: string }[],
  ): Promise<void> => {
    const updateTask = await api.updateDocuments(taskId, add, remove);
    setTask(updateTask);
    DrStore.dispatch("room/tasksRelated/update", tasksList.value);
  };

  const updateFolders = async (
    taskId: number,
    add: { folder_id: string }[],
    remove: { folder_id: string }[],
  ): Promise<void> => {
    const updateTask = await api.updateFolders(taskId, add, remove);
    setTask(updateTask);
    DrStore.dispatch("room/tasksRelated/update", tasksList.value);
  };

  const updateLabels = async (
    taskId: number,
    add: { label_key_id: number }[],
    remove: { label_key_id: number }[],
  ): Promise<void> => {
    const updateTask = await api.updateLabels(taskId, add, remove);
    setTask(updateTask);
  };

  // FIXME: this method doesn't follow the add/remove pattern, since task api doesn't implement respective method
  const unlinkFinding = async (taskId: string, findingId: string) => {
    await api.unlinkFinding(taskId, findingId);
    const finding = findingsStore.dict[findingId];
    const tasks = finding.tasks.filter(({ task_uid }) => task_uid !== taskId);
    findingsStore.applyChanges({ id: findingId, tasks });
  };

  const getDocumentDownloadUrl = (documentId: string) => {
    return api.getDocumentDownloadUrl(documentId);
  };

  const setIsReviewed = async (taskId: number, isReviewed: boolean) => {
    const updatedTask = await api.setIsReviewed(taskId, isReviewed);
    setTask(updatedTask);
  };

  const remindTaskParticipant = (
    taskId: Task["id"],
    user: ParticipantUserV1,
  ) => {
    return api
      .remindTaskParticipant(taskId, user)
      .then(() => {
        const task = tasks.value[taskId];

        if (!task) return;

        setTask({
          ...task,
          users_reminded: [...(task.users_reminded || []), user],
        });
      })
      .catch(() => {
        $notifyDanger("Something went wrong while remind the user.");
      });
  };

  const bulkUpdateAssignees = (
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
    overwrite_existing = false,
  ) => {
    return api
      .bulkUpdateAssignees(task_ids, user_ids, overwrite_existing)
      .then((updatedTasks) => {
        for (const t of updatedTasks) setTask(t);
        DrStore.dispatch("room/tasksRelated/update", tasksList.value);
      });
  };

  const bulkUpdateReviewers = (
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
    overwrite_existing = false,
  ) => {
    return api
      .bulkUpdateReviewers(task_ids, user_ids, overwrite_existing)
      .then((updatedTasks) => {
        for (const t of updatedTasks) setTask(t);
        DrStore.dispatch("room/tasksRelated/update", tasksList.value);
      });
  };

  const bulkUpdateFollowers = (
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
    overwrite_existing = false,
  ) => {
    return api
      .bulkUpdateFollowers(task_ids, user_ids, overwrite_existing)
      .then((updatedTasks) => {
        for (const t of updatedTasks) setTask(t);
        DrStore.dispatch("room/tasksRelated/update", tasksList.value);
      });
  };

  const bulkRemindParticipants = (
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
  ) => {
    return api
      .bulkRemindParticipants(task_ids, user_ids)
      .then((updatedTasks) => {
        for (const t of updatedTasks) setTask(t);
        DrStore.dispatch("room/tasksRelated/update", tasksList.value);
      });
  };

  const bulkUpdatePriority = (
    task_ids: Task["uid"][],
    priority: TaskPriority,
  ) => {
    return api.bulkUpdatePriority(task_ids, priority).then((updatedTasks) => {
      for (const t of updatedTasks) setTask(t);
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);
    });
  };

  const bulkUpdateStatus = (
    task_ids: Task["uid"][],
    status_id: Task["status_id"],
  ) => {
    return api.bulkUpdateStatus(task_ids, status_id).then((updatedTasks) => {
      for (const t of updatedTasks) setTask(t);
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);
    });
  };

  /**
   * Move multiple tasks into specified category.
   *
   * @param task_ids      An array of task UIDs to be moved.
   * @param category_id   The UID of the new category to move the tasks to.
   * @returns             A Promise that resolves to the updated tasks.
   */
  const bulkMove = (task_ids: Task["uid"][], category_id: Category["uid"]) => {
    return api.bulkMove(task_ids, category_id).then((updatedTasks) => {
      for (const t of updatedTasks) setTask(t);
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);
    });
  };

  /**
   * Bulk update labels for specified tasks.
   *
   * @param task_ids 	          Task uids list
   * @param labels_ids 	        Task labels uids list
   * @param overwrite_existing  Overwrite existing labels
   * @returns                   A Promise that resolves to the updated tasks.
   */
  const bulkUpdateLabels = (
    task_ids: Task["uid"][],
    label_ids: TaskLabel["uid"][],
    overwrite_existing = false,
  ) => {
    return api
      .bulkUpdateLabels(task_ids, label_ids, overwrite_existing)
      .then((updatedTasks) => {
        for (const t of updatedTasks) setTask(t);
        DrStore.dispatch("room/tasksRelated/update", tasksList.value);
      });
  };

  /**
   * Copy multiple tasks into specified category.
   *
   * @param task_ids      An array of task UIDs to be copied.
   * @param category_id   The UID of the new category to copy the tasks to.
   * @returns             A Promise that resolves to the copied tasks.
   */
  const bulkCopy = (task_ids: Task["uid"][], category_id: Category["uid"]) => {
    return api.bulkCopy(task_ids, category_id).then((copiedTasks) => {
      tasksList.value.push(...copiedTasks);
      DrStore.dispatch("room/tasksRelated/update", tasksList.value);
    });
  };

  /**
   * Archives multiple tasks.
   *
   * @param task_ids    An array of task UIDs to be archived.
   * @returns           A promise that resolves when the tasks are successfully archived.
   */
  const bulkArchive = (task_ids: Task["uid"][]) => {
    return api
      .bulkArchive(task_ids)
      .then(() => Promise.all([tasksArchivedStore.load(), load()]))
      .catch(() => {
        $notifyDanger("Something went wrong while archiving the tasks.");
      });
  };

  /**
   * Restores multiple tasks.
   *
   * @param task_ids    An array of task UIDs to be restored.
   * @returns           A promise that resolves when the tasks are successfully restored.
   */
  const bulkRestore = (task_ids: Task["uid"][]) => {
    return api
      .bulkRestore(task_ids)
      .then(() => Promise.all([tasksArchivedStore.load(), load()]))
      .catch(() => {
        $notifyDanger("Something went wrong while restoring the tasks.");
      });
  };

  const exportTasks = async (
    task_ids: Task["uid"][],
    format: "pdf" | "xlsx",
  ) => {
    const { file, filename } = await api.bulkExport(task_ids, format);
    dowloadFile(file, filename);
  };

  const importFromExcelFile = async (file: File) => {
    const result = await api.importFromExcelFile(file);
    if (["success", "partial"].includes(result.status)) {
      categoriesStore.load(true);
      reloadTasks();
      tasksLabelsStore.load();
      DrStore.dispatch("room/members/load");
    }
    return result;
  };

  const canExportCategory = (category: Category) => {
    // `category.descendants` includes the category itself
    return category.descendants.some(
      (catId) => tasksByCategoryId.value[catId]?.length,
    );
  };

  const exportCategory = (format: "pdf" | "xlsx", category?: Category) => {
    if (!category) {
      return exportTasks(
        tasksList.value.map((task) => task.uid),
        format,
      );
    }

    // `category.descendants` includes the category itself
    const tasksUidsToExport = category.descendants.reduce<Task["uid"][]>(
      (acc, catId) => {
        if (!tasksByCategoryId.value[catId]?.length) return acc;

        return acc.concat(
          tasksByCategoryId.value[catId].map((task) => task.uid),
        );
      },
      [],
    );

    return exportTasks(tasksUidsToExport, format);
  };

  const reorderTask = (
    taskId: number,
    prevObjId: number,
    isPrevObjCategory: boolean,
  ) => {
    const task = tasks.value[taskId];
    const newCategoryId = isPrevObjCategory
      ? prevObjId
      : tasks.value[prevObjId].category_id;
    const categoryChanged = newCategoryId !== task.category_id;

    let newOrder = isPrevObjCategory ? 0 : tasks.value[prevObjId].order;
    if (!isPrevObjCategory && (categoryChanged || newOrder < task.order)) {
      // for example items in order a, b, c:
      // order increased like b, c, a -> decrease b and c index and use prev item index
      // order decreased like a, c, b -> increase b index and use prev item index + _1_
      newOrder += 1;
    }

    if (!categoryChanged && task.order === newOrder) return;

    const siblings = tasksList.value.filter(
      (sib) => sib.category_id === newCategoryId,
    );
    siblings.sort((a, b) => a.order - b.order); //smaller index first

    if (categoryChanged) {
      // tasks is moved, add some space for new task in category
      siblings.forEach((sib) => {
        if (sib.order >= newOrder) sib.order += 1;
      });
    } else {
      // task position changed in same category
      if (newOrder > task.order) {
        siblings.forEach((sib) => {
          if (sib.order > task.order && sib.order <= newOrder) sib.order -= 1;
        });
      } else {
        siblings.forEach((sib) => {
          if (sib.order >= newOrder && sib.order < task.order) sib.order += 1;
        });
      }
    }

    const patchPayload: Partial<Task> = { order: newOrder };
    if (categoryChanged) patchPayload.category_id = newCategoryId;

    api.patchTaskV2(task.uid, patchPayload);

    task.order = newOrder;
    task.category_id = newCategoryId;
  };

  return {
    loadingPromise,

    tasks,
    tasksList,
    tasksByUid,
    tasksByCategoryId,

    dependantsMap,

    setTask, // for compatibility with angular, do not use in vue
    deleteTask, // for compatibility with angular, do not use in vue

    isLoading,
    isError,

    load,
    loadTaskDetails,
    reloadTaskById,
    createTaskV2,
    deleteTaskV2,
    patchTaskV2,
    patchTaskBulkV2,
    deleteTasksLabel,
    moveTaskToTop,
    moveTask,
    reorderTask,
    addComment,
    deleteTasksV2,
    restoreTasksV2,

    setTaskCommentInput,
    getTaskCommentInput,

    updateDependencies,
    updateAssignees,
    updateReviewers,
    updateFollowers,
    updateDocuments,
    updateFolders,
    updateLabels,
    bulkUpdateAssignees,
    bulkUpdateReviewers,
    bulkUpdateFollowers,
    bulkRemindParticipants,
    bulkUpdatePriority,
    bulkUpdateStatus,
    bulkMove,
    bulkUpdateLabels,
    bulkCopy,
    bulkArchive,
    bulkRestore,

    unlinkFinding,
    updateTaskLastVisitDate,
    setIsReviewed,
    remindTaskParticipant,

    getDocumentDownloadUrl,

    exportTasks,
    canExportCategory,
    exportCategory,
    importFromExcelFile,
  };
});
