import { reactive } from "vue";

import taskDetail_attachDocumentHtml from "../../../templates/components/task-detail/attach-document.html?raw";

TasksService.$inject = [
  "$uibModal",
  "$state",
  "$stateParams",
  "$location",
  "URLS",
  "Upload",
  "TrackActivity",
  "FileUploadService",
  "AlertService",
  "CategoriesService",
  "MembersService",
  "TasksAttachmentsService",
  "drLabelKey",
  "NG_APP",
];

import * as Sentry from "@sentry/browser";

import { insightTrack } from "@app/insight";
import { DrStore } from "@drVue";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import AttachToRequestModalController from "../controllers/AttachToRequestModalController";
import updateTaskAttributes from "./helpers/updateTaskAttributes";

export default function TasksService(
  $uibModal,
  $state,
  $stateParams,
  $location,
  URLS,
  Upload,
  TrackActivity,
  FileUploadService,
  AlertService,
  CategoriesService,
  MembersService,
  TasksAttachmentsService,
  drLabelKey,
  NG_APP,
) {
  const tasksStore = useTasksStore(pinia);
  const tasksArchivedStore = useTasksArchivedStore(pinia);

  var service = {
    // Moved to Tasks Store, see below...
    // tasks: {},
    // tasksList: undefined,
    // tasksLoading: undefined,
    // tasksLoadError: undefined,
    // loadingPromise: undefined,
    // isLoaded: isLoaded,
    // archived tasks info
    // archivedTasks: {},
    // archivedTasksList: undefined,
    // archivedTasksLoading: false,
    // archivedTasksLoadError: false,

    isLoaded: () => {
      return tasksStore.isLoading === false && tasksStore.isError === false;
    },

    // get data
    loadTasks: (skipErrorAlert) => tasksStore.load(skipErrorAlert),
    loadArchivedTasks: (skipErrorAlert) =>
      tasksArchivedStore.load(skipErrorAlert),
    patchTaskV2: (taskId, changes) => tasksStore.patchTaskV2(taskId, changes),

    // task detail actions
    openTaskDetails: openTaskDetails,
    onUploadToTaskFilesDrop: onUploadToTaskFilesDrop,
    showAddToTaskFilesModal: showAddToTaskFilesModal,
    showAttachFilesWithCallbackModal: showAttachFilesWithCallbackModal,
  };

  Object.defineProperties(service, {
    tasks: {
      get: () => tasksStore.tasks,
    },
    tasksByUid: {
      get: () => tasksStore.tasksByUid,
    },
    tasksList: {
      get: () => tasksStore.tasksList,
    },
    tasksLoading: {
      get: () => tasksStore.isLoading,
    },
    tasksLoadError: {
      get: () => tasksStore.isError,
    },
    loadingPromise: {
      get: () => tasksStore.loadingPromise,
    },
    archivedTasks: {
      get: () => tasksArchivedStore.tasks,
    },
    archivedTasksList: {
      get: () => tasksArchivedStore.tasksList,
    },
    archivedTasksLoading: {
      get: () => tasksArchivedStore.isLoading,
    },
    archivedTasksLoadError: {
      get: () => tasksArchivedStore.isError,
    },
  });

  return service;

  function openTaskDetails(taskKey) {
    const listTask = service.tasksList?.find((t) => t.key === taskKey);

    // We call `loadTaskDetails` every time to get the latest data. Internally,
    // it will update the `tasksList` within the store even if the task is
    // already loaded, and we `Promise.resolve(listTask)`.
    const loadTaskPromsie = tasksStore.loadTaskDetails(taskKey);

    const promises = [
      listTask ? Promise.resolve(listTask) : loadTaskPromsie,
      MembersService.isLoaded()
        ? Promise.resolve(MembersService.membersList)
        : DrStore.dispatch("room/members/load", true),
    ];

    Promise.all(promises).then(([task, _members]) => openModal(task));

    function openModal(task) {
      updateTaskAttributes(task);

      const modalInstance = $uibModal.open({
        controller: [
          "$scope",
          "task",
          ($scope, task) => {
            $scope.task = reactive(task);
            $scope.onClose = () => {
              // $scope.$close will resolve modalInstance.result promise in TasksService.js
              $scope.$close();
            };
          },
        ],
        template: `<vue-component id="room_TaskDetails" name="VueTaskDetails" v-props="{ task: task, onClose: onClose }"></vue-component>`,
        keyboard: false,
        backdrop: false,
        windowClass: "modal--hidden",
        animation: false,
        resolve: {
          task: () => task,
        },
      });

      const uiRouterEnabled = $location.url(); // there is no # part in fileviewer

      modalInstance.rendered.then(() => {
        if (NG_APP === "dealroom") {
          if (uiRouterEnabled) {
            $state.go(
              "tasks.details",
              { taskId: task.key },
              {
                notify: false,
                inherit: false,
              },
            );
          }

          TrackActivity.setObject({ type: "task", id: task.id });
        }
      });

      const lastState = $state.$current.name;
      const lastParams = angular.copy($stateParams);

      modalInstance.result.finally(() => {
        if (uiRouterEnabled) {
          $state.go(lastState, lastParams, {
            notify: false,
            inherit: false,
          });
        }

        TrackActivity.setObject(undefined);
      });
    }
  } /* End openTaskDetails */

  //////////////////////////////////////////////////////////////////////////////
  // Task detail edit

  function onUploadToTaskFilesDrop(task, files) {
    // ignore text paste to description
    if (!files || !files.length) return;
    showAddToTaskFilesModal(task, files);
  }

  function showAddToTaskFilesModal(task, files, getInitialDestinationFolderId) {
    function callback(folders, documents, hasDestinationFolderChanged) {
      TasksAttachmentsService.createAttachments(task, folders, documents);

      insightTrack("room.documents.upload", {
        source: "task",
        task_id: task.id,
        files: documents.length,
        destination_changed: hasDestinationFolderChanged,
      });
    }

    const taskPath = CategoriesService.categories[task.category_id].full_path;
    service.showAttachFilesWithCallbackModal(
      taskPath,
      files,
      callback,
      getInitialDestinationFolderId?.(taskPath),
    );
  }

  function showAttachFilesWithCallbackModal(
    itemPath,
    files,
    callback,
    initialDestinationFolderId,
  ) {
    FileUploadService.dragLabel = undefined;

    $uibModal.open({
      backdrop: true,
      openedClass: "upload-window-opened",
      windowClass: "upload-window",
      template: taskDetail_attachDocumentHtml,
      resolve: {
        itemPath: () => itemPath,
        onUploadComplete: () => callback,
        files: () => files,
        initialDestinationFolderId: () => initialDestinationFolderId,
      },
      animation: false,
      controllerAs: "$ctrl",
      controller: AttachToRequestModalController,
    });
  }
}
