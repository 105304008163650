RoomTaskController.$inject = ["$scope", "$timeout", "$state", "TasksService"];
import { isTask } from "@app/ng/tasks/services/helpers/getItemType";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";
import { pinia } from "@drVue/store/pinia";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";

const tasksTableStore = useTasksTableStore(pinia);

export default function RoomTaskController(
  $scope,
  $timeout,
  $state,
  TasksService,
) {
  var self = this;

  self.$state = $state;
  self.TasksService = TasksService;

  tasksFilterService.setOrder({ by: "order", reversed: false });

  tasksTableStore.isActive = true;

  self.openTaskDetails = (row) => {
    if (isTask(row)) self.TasksService.openTaskDetails(row.key);
  };

  self.navigate = ({ listId }) => {
    if ($state.$current.name === "tasks.archived.overview") {
      $state.go("tasks.list.overview", {
        listId,
      });
    } else {
      $state.go($state.$current.name, { listId });
    }
  };

  $timeout(function () {
    if ($state.$current.name === "tasks") {
      $state.go("tasks.list");
    }
  });
}
