<template>
  <DrItemList :items="displayedAttachments">
    <template #header>
      <DrPopup ref="popupRef" paddingless :disabled="viewOnly">
        <template #reference>
          <DrItemListHeader
            :title="title"
            :light-weight="!attachments.length"
            :allow-action="!viewOnly"
          />
        </template>

        <template #default>
          <TreeBrowser
            v-model:selected-node-ids="attachmentUids"
            is-multiple
            :width="POPUP_SIZES.wideTree.width"
            :height="POPUP_SIZES.wideTree.height"
            :is-loading="isTreeLoading"
            :tree="documentsStore.attachmentsTree"
            @save="handleRoomBrowserSubmit"
            @cancel="handleCloseRoomBrowser"
            @create-node="handleCreateFolder"
          >
            <template #tree-item="{ item }">
              <div :class="$style.treeItemContainer">
                <DrIcon
                  v-if="(item as Partial<Folder>).type === 'folder'"
                  :class="$style.folderIcon"
                  name="folder"
                />
                <i
                  v-else
                  class="mimetypes"
                  :class="[
                    $style.fileIcon,
                    getIconClass((item as Partial<Document>).mimetype),
                  ]"
                />
                <span>{{ (item as unknown as Document).name }}</span>
              </div>
            </template>
          </TreeBrowser>
        </template>
      </DrPopup>
    </template>

    <template v-if="!viewOnly" #header-right>
      <DrUploadButton
        v-if="IS_NEW_UPLOAD_DIALOG_ENABLED"
        size="small"
        @selected="(files) => $emit('upload', files)"
      />

      <span v-else :class="$style.uploadButton" @click="openUploadDialog">
        <DrIcon name="upload" size="sm" />
        <span :class="$style.uploadLabel">Upload</span>
      </span>
    </template>

    <template #item="{ item }">
      <AttachmentItem
        :attachment="item"
        :disallow-remove="viewOnly"
        @remove="handleRemove"
      />
    </template>

    <template v-if="hasExpander" #footer>
      <div
        :class="$style.expander"
        @click.stop.prevent="isExpanded = !isExpanded"
      >
        <span>Show {{ isExpanded ? "less" : "more" }}</span>
        <DrIcon
          size="xs"
          :name="isExpanded ? 'caret-up' : 'caret-down'"
          :class="$style.expanderIcon"
        />
      </div>
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrItemList, DrItemListHeader } from "@shared/ui/dr-item-list";
import { DrPopup } from "@shared/ui/dr-popups";

import getIconClass from "@app/common/mimetype";
import { ROOM_DATA } from "@app/setups";
import { loadFromLocalStorage } from "@app/vue/common";
import DrUploadButton from "@app/vue/shared/ui/dr-upload-button/DrUploadButton.vue";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { TreeBrowser } from "@drVue/components/room/tasks/shared/tree-browser";
import {
  type Document,
  DocumentsApiService,
  type Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { openUploadToFindingDialog } from "../../utils";
import AttachmentItem from "./AttachmentItem.vue";

import type { FindingsTableRow } from "../../types";
import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";

interface Props {
  finding: FindingsTableRow;
  viewOnly: boolean;
}

interface Emits {
  (event: "upload", files: File[]): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const IS_NEW_UPLOAD_DIALOG_ENABLED =
  !!loadFromLocalStorage("dr:enable_new_upload_dialog") ||
  ROOM_DATA.enableNewUploadDialog;

const SHOW_MORE_COUNT = 5;

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);
const isTreeLoading = ref(false);

const docsApi = new DocumentsApiService();

const documentsStore = useDocumentsStore();
const findingsStore = useFindingsStore();

const attachments = computed<(Document | Folder)[]>(() =>
  [
    ...props.finding.documents.map(({ document_id }) =>
      documentsStore.treeRaw?.Files.find((item) => item.uid === document_id),
    ),
    ...props.finding.folders.map(({ folder_id }) =>
      documentsStore.treeRaw?.Folders.find((item) => item.uid === folder_id),
    ),
  ].filter((attachment): attachment is Document | Folder => !!attachment),
);

const attachmentUids = computed<NodeId[]>(() =>
  attachments.value.map(({ uid }) => uid),
);

const title = computed(() => {
  if (attachments.value.length) {
    return `Attachments (${attachments.value.length})`;
  }
  return `Attachments`;
});
const hasExpander = computed(() => attachments.value.length > SHOW_MORE_COUNT);
const isExpanded = ref(false);

const displayedAttachments = computed(() => {
  return isExpanded.value
    ? attachments.value
    : attachments.value.slice(0, SHOW_MORE_COUNT);
});

const handleUpdateDocuments = (docs: { document_id: string }[]) => {
  return findingsStore.updateDocuments(props.finding.id, docs);
};

const handleUpdateFolders = (folders: { folder_id: string }[]) => {
  return findingsStore.updateFolders(props.finding.id, folders);
};

const handleRoomBrowserSubmit = async (payload: NodeId[]) => {
  isTreeLoading.value = true;
  const docs = payload.filter((uid) => String(uid).startsWith("doc"));
  const folders = payload.filter((uid) => String(uid).startsWith("fldr"));

  await handleUpdateDocuments(
    docs.map((uid) => ({ document_id: String(uid) })),
  );

  await handleUpdateFolders(folders.map((uid) => ({ folder_id: String(uid) })));

  isTreeLoading.value = false;
  popupRef.value?.hide();
  return;
};

const handleRemove = ({ uid }: { uid: string }) => {
  if (uid.startsWith("doc")) {
    handleUpdateDocuments(
      props.finding.documents.filter((d) => d.document_id !== uid),
    );
  } else if (uid.startsWith("fldr")) {
    handleUpdateFolders(
      props.finding.folders.filter((f) => f.folder_id !== uid),
    );
  } else throw new Error("Invalid uid");
};

const handleCreateFolder = async ({
  parentId,
  name,
}: {
  parentId: NodeId;
  name: string;
}) => {
  const parent = documentsStore.treeRaw?.Folders.find(
    ({ uid }: DocsItem) => uid === parentId,
  );
  if (!parent) return;

  isTreeLoading.value = true;
  await docsApi.createFolder(parent.id, name);
  await documentsStore.syncTree();
  isTreeLoading.value = false;
};

const handleCloseRoomBrowser = () => {
  popupRef.value?.hide();
};

const openUploadDialog = () => {
  openUploadToFindingDialog(props.finding.id, []);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.actions {
  display: flex;
  flex-direction: column;
  font: typography.$body_regular;
  color: colors.$pr-900;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 8px;
    padding: 8px;
    margin: 0 -8px;

    &:first-child {
      margin-top: -8px;
    }

    &:last-child {
      margin-bottom: -8px;
    }

    &:hover {
      background-color: colors.$pr-100;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  font: typography.$body_regular;
  color: colors.$pr-900;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 8px;
    padding: 8px;
    margin: 0 -8px;

    &:first-child {
      margin-top: -8px;
    }

    &:last-child {
      margin-bottom: -8px;
    }

    &:hover {
      background-color: colors.$pr-100;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
}

.uploadLabel {
  margin-left: 4px;
}

.uploadButton {
  display: flex;
  align-items: center;
  color: colors.$pr-400;
  margin-left: auto;

  &:hover {
    cursor: pointer;
    color: colors.$pr-900;

    .uploadLabel {
      text-decoration: underline;
    }
  }
}

.treeItemContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 6px;
  align-items: center;
}

.folderIcon {
  color: colors.$pr-400;
}

.fileIcon {
  scale: 0.7;
}

.expander {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xxs;
  font: typography.$body_regular;
  color: colors.$pr-500;
  cursor: pointer;
}

.expanderIcon {
  color: colors.$pr-400;
}
</style>
