<template>
  <div
    :class="{
      [$style.composer]: true,
      [$style.composer_isActive]: isActive,
    }"
  >
    <ElForm>
      <DrEditor
        ref="editorRef"
        v-model:model-value="commentDraft.body"
        :class="$style.editorElement"
        :options="{
          showMenuOnFocus: true,
          placeholder: 'Write a comment...',
        }"
        :mentions="mentions"
        @focus="isActive = true"
      />
      <EditorActions
        :visible="isActive"
        :task-category-id="taskCategoryId"
        :is-public="commentDraft.is_public"
        :viewers="commentDraft.viewers"
        :viewers-groups="viewersGroups"
        :can-manage-permissions="canManagePermissions"
        @submit-comment="handleSubmitComment"
        @update-permissions="handleUpdatePermissions"
      />
    </ElForm>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, unref } from "vue";
import DrEditor from "@shared/ui/editor";

import { ROOM_DATA } from "@setups/data";
import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { $notifyDanger } from "@drVue/common";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import EditorActions from "./composer/EditorActions.vue";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { ITaskCommentDraft } from "@drVue/store/modules/room/types";
import type { Dictionary } from "@drVue/types";

interface Props {
  canManagePermissions: boolean;
  taskId: number;
  taskCategoryId: number;
}

const props = defineProps<Props>();

const emit = defineEmits(["submit-success"]);

const viewersGroups = computed<Dictionary<Group>>(() => {
  const { needCommentsApprove } = ROOM_DATA.userPermissions;

  return DrStore.state.room.groups.pgroupsList.reduce<Dictionary<Group>>(
    (groups, group) => {
      if (!group.symmetrical_view) {
        return groups;
      }

      if (
        group.is_administrator ||
        !needCommentsApprove ||
        group.id === ROOM_DATA.userPermissions.id
      ) {
        groups[group.id] = group;
      }
      return groups;
    },
    {},
  );
});

const defaultViewersGroups = ROOM_DATA.userPermissions.defaultComments
  ? []
  : [ROOM_DATA.userPermissions.id];

const commentDraft = reactive<ITaskCommentDraft>({
  task_id: props.taskId,
  is_public: !defaultViewersGroups.length,
  body: null!, // FIXME null!
  viewers: defaultViewersGroups,
});
const handleSubmitComment = async () => {
  try {
    await tasksStore.addComment(props.taskId, commentDraft);
    commentDraft.body = null!;
    emit("submit-success");
    insightTrack(TasksTableTrackingEvent.CommentModalCommentAdded);
  } catch (e) {
    $notifyDanger("Something went wrong while commenting the task");
  }
};
// TODO: add signature type
const handleUpdatePermissions = (perms: {
  isPublic: boolean;
  viewers: number[];
}) => {
  commentDraft.is_public = perms.isPublic;
  commentDraft.viewers = perms.viewers;
};
const tasksStore = useTasksStore();
const mentions = computed(() => ({
  tasks: tasksStore.tasksList,
  users: DrStore.state.room.members.membersList,
}));

// UI
const isActive = ref(false);
const editorRef = ref();
const focus = () => {
  unref(editorRef)?.focus();
};
defineExpose({ focus });
</script>

<style lang="scss" module>
@use "sass:color";

@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography.scss";

.composer {
  margin: 10px 0 12px 0;
  border: 1px solid colors.$pr-300;
  border-radius: 8px;
  height: 34px;
  :global(.dr-editor__wrapper) {
    height: 32px;
  }
}

.composer_isActive {
  box-shadow: 0 0 0 3px color.adjust(colors.$sc-300, $alpha: -0.2);
  height: auto;

  :global(.dr-editor__wrapper) {
    height: auto;
  }
}

.editorElement {
  :global(.dr-editor__wrapper) {
    border-radius: 8px;
    border: unset;
  }
  :global(.user-information__icon) {
    display: none;
  }
  :global(.user-information__name::before) {
    content: "@";
    font-size: 14px;
  }
}
</style>
