<template>
  <DrItemList :items="displayedAttachments">
    <template #header>
      <DrPopup ref="popupRef" paddingless :disabled="viewOnly">
        <template #reference>
          <DrItemListHeader
            :title="title"
            :light-weight="!fileIds.length"
            :allow-action="!viewOnly"
            @action="handlePlusClick"
          />
        </template>
        <template #default>
          <TreeBrowser
            v-model:selected-node-ids="attachmentUids"
            is-multiple
            :width="POPUP_SIZES.wideTree.width"
            :height="POPUP_SIZES.wideTree.height"
            :is-loading="isTreeLoading"
            :tree="documentsStore.attachmentsTree"
            @save="handleRoomBrowserSubmit"
            @cancel="handleCloseRoomBrowser"
            @create-node="handleCreateFolder"
          >
            <template #tree-item="{ item }">
              <div :class="$style.treeItemContainer">
                <DrIcon
                  v-if="(item as Partial<Folder>).type === 'folder'"
                  :class="$style.folderIcon"
                  name="folder"
                />
                <i
                  v-else
                  class="mimetypes"
                  :class="[
                    $style.fileIcon,
                    getIconClass((item as Partial<Document>).mimetype),
                  ]"
                />
                <span>{{ (item as unknown as Document).name }}</span>
              </div>
            </template>
          </TreeBrowser>
        </template>
      </DrPopup>
    </template>

    <template v-if="!viewOnly" #header-right>
      <DrUploadButton
        v-if="IS_NEW_UPLOAD_DIALOG_ENABLED"
        size="small"
        @selected="(files) => $emit('upload', files)"
      />

      <span v-else :class="$style.uploadButton" @click="openUploadDialog">
        <DrIcon name="upload" size="sm" />
        <span :class="$style.uploadLabel">Upload</span>
      </span>
    </template>

    <template #item="{ item }">
      <AttachmentItem
        :attachment="item"
        :disallow-remove="viewOnly"
        @remove="handleRemove"
      />
    </template>

    <template v-if="hasExpander" #footer>
      <div
        :class="$style.expander"
        @click.stop.prevent="isExpanded = !isExpanded"
      >
        <span>Show {{ isExpanded ? "less" : "more" }}</span>
        <DrIcon
          size="xs"
          :name="isExpanded ? 'caret-up' : 'caret-down'"
          :class="$style.expanderIcon"
        />
      </div>
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { difference } from "lodash-es";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrItemList, DrItemListHeader } from "@shared/ui/dr-item-list";
import { DrPopup } from "@shared/ui/dr-popups";
import DrUploadButton from "@shared/ui/dr-upload-button/DrUploadButton.vue";

import getIconClass from "@app/common/mimetype";
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { TasksServiceProxy } from "@app/ng/serviceProxies";
import { ROOM_DATA } from "@app/setups";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { loadFromLocalStorage } from "@drVue/common";
import { TreeBrowser } from "@drVue/components/room/tasks/shared/tree-browser";
import {
  type Document,
  DocumentsApiService,
  type Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import AttachmentItem from "./AttachmentItem.vue";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { TaskDocument, TaskFolder } from "@drVue/store/pinia/room/tasks";

interface Props {
  taskId: number;
  fileIds: TaskDocument[];
  folderIds: TaskFolder[];
  viewOnly: boolean;
}

interface Emits {
  (event: "upload", files: File[]): void;
}

const IS_NEW_UPLOAD_DIALOG_ENABLED =
  !!loadFromLocalStorage("dr:enable_new_upload_dialog") ||
  ROOM_DATA.enableNewUploadDialog;

const props = defineProps<Props>();
defineEmits<Emits>();

const { t } = useI18n();

const documentsStore = useDocumentsStore();

const SHOW_MORE_COUNT = 5;

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);
const isTreeLoading = ref(false);

const docsApi = new DocumentsApiService();

const tasksService = new TasksServiceProxy();
const tasksStore = useTasksStore();

const attachments = computed(() => [
  ...props.fileIds.map(({ document_id, date_added }) => ({
    ...documentsStore.fileByIdMap[document_id],
    date_added,
  })),
  ...props.folderIds.map(({ folder_id, date_added }) => ({
    ...documentsStore.folderByIdMap[folder_id],
    date_added,
  })),
]);

const attachmentUids = computed<NodeId[]>(() =>
  attachments.value.map(({ uid }) => uid),
);

const title = computed(() => {
  if (attachments.value.length) {
    return t("shared.attachments_count", { count: attachments.value.length });
  }

  return t("shared.attachments");
});
const hasExpander = computed(() => attachments.value.length > SHOW_MORE_COUNT);
const isExpanded = ref(false);

const displayedAttachments = computed(() => {
  return isExpanded.value
    ? attachments.value
    : attachments.value.slice(0, SHOW_MORE_COUNT);
});

const handleUpdateDocuments = async (
  add: { document_id: string }[],
  remove: { document_id: string }[],
) => {
  await tasksStore.updateDocuments(props.taskId, add, remove);
  if (add.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentAdded, {
      type: "documents",
    });
  }
  if (remove.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentRemoved, {
      type: "documents",
    });
  }
};

const handleUpdateFolders = async (
  add: { folder_id: string }[],
  remove: { folder_id: string }[],
) => {
  await tasksStore.updateFolders(props.taskId, add, remove);
  if (add.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentAdded, {
      type: "folders",
    });
  }
  if (remove.length) {
    insightTrack(TaskDetailsTrackingEvent.AttachmentRemoved, {
      type: "folders",
    });
  }
};

const handleRoomBrowserSubmit = async (payload: NodeId[]) => {
  isTreeLoading.value = true;
  const add = difference(payload, attachmentUids.value);
  const remove = difference(attachmentUids.value, payload);
  const addDocs = add.filter((uid) => uid.toString().startsWith("doc"));
  const addFolders = add.filter((uid) => uid.toString().startsWith("fldr"));
  const removeDocs = remove.filter((uid) => uid.toString().startsWith("doc"));
  const removeFolders = remove.filter((uid) =>
    uid.toString().startsWith("fldr"),
  );
  if (addDocs.length || removeDocs.length) {
    await handleUpdateDocuments(
      addDocs.map((uid) => ({ document_id: uid.toString() })),
      removeDocs.map((uid) => ({ document_id: uid.toString() })),
    );
  }
  if (addFolders.length || removeFolders.length) {
    await handleUpdateFolders(
      addFolders.map((uid) => ({ folder_id: uid.toString() })),
      removeFolders.map((uid) => ({ folder_id: uid.toString() })),
    );
  }
  isTreeLoading.value = false;
  popupRef.value?.hide();
  return;
};

const handleRemove = ({ uid }: { uid: string }) => {
  if (uid.startsWith("doc")) {
    handleUpdateDocuments([], [{ document_id: uid }]);
  } else if (uid.startsWith("fldr")) {
    handleUpdateFolders([], [{ folder_id: uid }]);
  } else throw new Error("Invalid uid");
};

const handleCreateFolder = async ({
  parentId,
  name,
}: {
  parentId: NodeId;
  name: string;
}) => {
  const parent = documentsStore.treeRaw?.Folders.find(
    (item) => item.uid === parentId,
  );
  if (!parent) return;
  isTreeLoading.value = true;
  await docsApi.createFolder(parent.id, name);
  await documentsStore.syncTree();
  isTreeLoading.value = false;
};

const handleCloseRoomBrowser = () => {
  popupRef.value?.hide();
};

const openUploadDialog = () => {
  tasksService.showAddToTaskFilesModal(
    tasksStore.tasks[props.taskId],
    undefined,
    documentsStore.suggestUploadFolder,
  );
  insightTrack(TaskDetailsTrackingEvent.AttachmentUpload);
};

const handlePlusClick = () =>
  insightTrack(TaskDetailsTrackingEvent.AttachmentAdd);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.actions {
  display: flex;
  flex-direction: column;
  font: typography.$body_regular;
  color: colors.$pr-900;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 8px;
    padding: 8px;
    margin: 0 -8px;

    &:first-child {
      margin-top: -8px;
    }

    &:last-child {
      margin-bottom: -8px;
    }

    &:hover {
      background-color: colors.$pr-100;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  font: typography.$body_regular;
  color: colors.$pr-900;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 8px;
    padding: 8px;
    margin: 0 -8px;

    &:first-child {
      margin-top: -8px;
    }

    &:last-child {
      margin-bottom: -8px;
    }

    &:hover {
      background-color: colors.$pr-100;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
}

.uploadLabel {
  margin-left: 4px;
}

.uploadButton {
  display: flex;
  align-items: center;
  color: colors.$pr-400;
  margin-left: auto;

  &:hover {
    cursor: pointer;
    color: colors.$pr-900;

    .uploadLabel {
      text-decoration: underline;
    }
  }
}

.treeItemContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 6px;
  align-items: center;
}

.folderIcon {
  color: colors.$pr-400;
}

.fileIcon {
  scale: 0.7;
}

.expander {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xxs;
  font: typography.$body_regular;
  color: colors.$pr-500;
  cursor: pointer;
}

.expanderIcon {
  color: colors.$pr-400;
}
</style>
