<template>
  <DrDrawer
    :title="t('requests.new_request')"
    :shown="isDrawerVisible"
    :custom-class="$style.drawer"
    size="custom"
    @close="close"
    @closed="$emit('closed')"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="requestSchema"
      :entity="request"
      :errors="formErrors"
      @update="handleUpdate"
      @submit="handleSubmit(false)"
    >
      <template #title="{ editProps, error, dataTestId }">
        <ElFormItem
          required
          :error="error"
          :label="editProps.schema.label"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <ElInput
            ref="titleInputRef"
            :validate-event="false"
            :model-value="editProps.value"
            :readonly="editProps.schema.isReadOnly"
            :placeholder="editProps.schema.placeholder"
            v-bind="editProps.veeField"
          />
        </ElFormItem>
      </template>
      <template #assignees="{ editProps, error, dataTestId }">
        <ElFormItem
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <template #label>
            <ParticipantsEdit :edit-props="editProps">
              <template #reference="{ show }">
                <DrFormItemLabelPlus
                  :title="editProps.schema.label"
                  @plus="show"
                />
              </template>
            </ParticipantsEdit>
          </template>

          <FindingParticipantsList
            :items="request.assignees"
            @remove="(u) => handleRemove('assignees', u, editProps)"
          />
        </ElFormItem>
      </template>
      <template #reviewers="{ editProps, error, dataTestId }">
        <ElFormItem
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <template #label>
            <ParticipantsEdit :edit-props="editProps">
              <template #reference="{ show }">
                <DrFormItemLabelPlus
                  :title="editProps.schema.label"
                  @plus="show"
                />
              </template>
            </ParticipantsEdit>
          </template>

          <FindingParticipantsList
            :items="request.reviewers"
            @remove="(u) => handleRemove('reviewers', u, editProps)"
          />
        </ElFormItem>
      </template>
      <template #followers="{ editProps, error, dataTestId }">
        <ElFormItem
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <template #label>
            <ParticipantsEdit :edit-props="editProps">
              <template #reference="{ show }">
                <DrFormItemLabelPlus
                  :title="editProps.schema.label"
                  @plus="show"
                />
              </template>
            </ParticipantsEdit>
          </template>

          <FindingParticipantsList
            :items="request.followers"
            @remove="(u) => handleRemove('followers', u, editProps)"
          />
        </ElFormItem>
      </template>
    </DrDynamicFormClassic>

    <template #footer>
      <ElButton :disabled="isSubmitting" @click="close">
        {{ t("shared.cancel") }}
      </ElButton>
      <ElButton :disabled="isSubmitting" @click="handleSubmit(false)">
        {{ t("requests.create_and_add") }}
      </ElButton>
      <ElButton
        type="primary"
        :disabled="isSubmitting"
        @click="handleSubmit(true)"
      >
        {{ t("shared.create") }}
      </ElButton>
    </template>
  </DrDrawer>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { capitalize } from "lodash-es";
import { cloneDeep } from "lodash-es";
import { isEqual } from "lodash-es";
import { set } from "lodash-es";
import { computed, reactive, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import DrDrawer from "@shared/ui/dr-drawer/DrDrawer.vue";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { mapCustomFieldToSchema } from "@shared/ui/dr-dynamic-form/utils";
import {
  validateNumber,
  validateString,
} from "@shared/ui/dr-dynamic-form/utils";
import { DrFormItemLabelPlus, useFormHelper } from "@shared/ui/dr-form";
import { PriorityValues } from "@shared/ui/dr-icon-priority";

import { ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { insightTrack, RoomTasksCreationEvent } from "@app/insight";
import { TasksServiceProxy } from "@app/ng/serviceProxies";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { serializeCustomData } from "@drVue/api-service/parse";
import { $notifySuccess, $notifyWarning } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import FindingParticipantsList from "@drVue/components/room/findings/components/FindingParticipantsList.vue";
import { getRichTextEmptyData } from "@drVue/components/room/findings/utils";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import DrStore from "@drVue/store";
import { TaskStatusType } from "@drVue/store/modules/room/tasks-statuses/TasksStatusesState";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import CategorySelect from "./CategorySelect.vue";
import DocumentsSelect from "./DocumentsSelect.vue";
import LabelsSelect from "./LabelsSelect.vue";
import PrioritySelect from "./PrioritySelect.vue";
import StatusSelect from "./StatusSelect.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type {
  CreateTaskPayload,
  DocumentUid,
  FolderUid,
  Task,
  TaskPriority,
  UserUid,
} from "@drVue/store/pinia/room/tasks";
import type { FieldProps, FieldSchema } from "@shared/ui/dr-dynamic-form/types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

interface Emits {
  (e: "closed"): void;
}

defineEmits<Emits>();

const { t } = useI18n();

const { formErrors, hookFormSubmitPromise, resetError, resetErrors } =
  useFormHelper<Task>();

const isDrawerVisible = ref(false);

const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);
const titleInputRef = ref<InstanceType<typeof ElInput> | null>(null);

const tasksStore = useTasksStore(pinia);
const tasksArchivedStore = useTasksArchivedStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const documentsStore = useDocumentsStore();

const taskService = new TasksServiceProxy();

const accessStartDueDatesFields =
  ROOM_MEMBER_DATA.group.task_start_and_due_dates_access ||
  TaskFieldAccessType.NoAccess;
const allowEditStartDueDatesFields =
  TaskFieldAccessType.Edit === accessStartDueDatesFields;

const requestSchema = computed<FieldSchema[]>(() => {
  const fields = [
    {
      type: FieldSchemaType.Custom,
      required: true,
      prop: "category_id",
      label: t("shared.worklist"),
      placeholder: t("shared.type_to_search"),
      editComponent: CategorySelect,
      rules: validateNumber().required(t("form.may_not_be_blank")),
      extra: {
        categories: categoriesStore.categoriesNavTree,
        categoriesList: categoriesStore.categoriesList,
      },
    },
    {
      type: FieldSchemaType.Text,
      required: true,
      rules: validateString().required(t("form.may_not_be_blank")),
      prop: "title",
      label: t("shared.title"),
      extra: {
        // It is implemented here in <CreateRequestPanel /> component.
        // See watch(isDrawerVisible) below.
        //
        // autofocus: 350,
        focusId: "title",
      },
    },
    {
      type: FieldSchemaType.Richtext,
      required: false,
      prop: "description",
      label: t("shared.description"),
    },
    {
      type: FieldSchemaType.Custom,
      required: false,
      prop: "attachments",
      label: t("shared.documents"),
      placeholder: t("shared.type_to_search"),
      editComponent: DocumentsSelect,
    },
    {
      type: FieldSchemaType.Custom,
      required: true,
      prop: "status_id",
      label: t("shared.status"),
      editComponent: StatusSelect,
      gridColumn: "1 / 7",
    },
    {
      type: FieldSchemaType.Custom,
      required: true,
      prop: "priority",
      label: t("shared.priority"),
      editComponent: PrioritySelect,
      extra: {
        select_options: PriorityValues.map((p) => ({
          value: p,
          label: capitalize(p),
        })),
      },
      gridColumn: "7 / 13",
    },
    allowEditStartDueDatesFields
      ? {
          type: FieldSchemaType.Date,
          required: false,
          prop: "start_date",
          label: t("shared.start_date"),
          gridColumn: "1 / 7",
        }
      : null,
    allowEditStartDueDatesFields
      ? {
          type: FieldSchemaType.Date,
          required: false,
          prop: "due_date",
          label: t("shared.due_date"),
          gridColumn: "7 / 13",
        }
      : null,
    {
      type: FieldSchemaType.Custom,
      required: false,
      prop: "labels",
      label: t("shared.labels"),
      placeholder: t("shared.type_to_search"),
      editComponent: LabelsSelect,
    },
    {
      type: FieldSchemaType.Custom,
      required: false,
      prop: "assignees",
      label: t("shared.assignees"),
      extra: {
        categoryId: request.category_id,
        isConfirmable: false,
      },
    },
    {
      type: FieldSchemaType.Custom,
      required: false,
      prop: "reviewers",
      label: t("shared.reviewers"),
      extra: {
        categoryId: request.category_id,
        isConfirmable: false,
      },
    },
    {
      type: FieldSchemaType.Custom,
      required: false,
      prop: "followers",
      label: t("shared.followers"),
      extra: {
        categoryId: request.category_id,
        isConfirmable: false,
      },
    },
    ...requestCustomFieldsSchema.value,
  ];

  return fields.filter((field) => field !== null) as FieldSchema[];
});

const access =
  ROOM_MEMBER_DATA.group.task_custom_fields_access ||
  TaskFieldAccessType.NoAccess;
const allowCustomFields = TaskFieldAccessType.Edit === access;

const customFields = computed((): FieldItem[] => {
  return DrStore.getters["clientDashboard/customFields/byObjectType"]("task");
});

const requestCustomFieldsSchema = computed<FieldSchema[]>(() =>
  allowCustomFields ? customFields.value.map(mapCustomFieldToSchema) : [],
);

const firstOpenStatus = DrStore.state.room.tasksStatuses.list.filter(
  (s) => s.type === TaskStatusType.Open,
)[0];

interface CreateRequestForm {
  category_id: number | null;
  status_id: number | null;

  title: string;
  description: ProsemirrorNode;

  priority: TaskPriority;

  start_date: Date | null;
  due_date: Date | null;

  assignees: UserUid[];
  followers: UserUid[];
  reviewers: UserUid[];

  attachments: {
    folders: FolderUid[];
    documents: DocumentUid[];
  };

  labels: number[];

  order?: number;

  custom_data: Record<string, any>;
}

const emptyRequest: Readonly<CreateRequestForm> = {
  category_id: null,
  status_id: firstOpenStatus.id,

  title: "",
  description: getRichTextEmptyData(),

  start_date: null,
  due_date: null,
  labels: [],
  priority: "low",
  assignees: [],
  reviewers: [],
  followers: [],

  attachments: {
    folders: [],
    documents: [],
  },

  order: undefined,
  custom_data: {},
};

const request = reactive<CreateRequestForm>(cloneDeep(emptyRequest));

const filterParticipants = (participantUids: UserUid[], categoryId: number) => {
  const memberByUid = DrStore.state.room.members.membersByUid;
  const pgroupById = DrStore.state.room.groups.pgroups;

  return participantUids.filter(({ user_id: uid }) => {
    const member = memberByUid[uid];
    if (!member) return false;

    const group = pgroupById[member.pgroup.id];
    if (!group) return false;

    return (
      group.can_task_manage &&
      group.viewable_categories_ids.includes(categoryId)
    );
  });
};

type Participants = "assignees" | "reviewers" | "followers";
const participants: Participants[] = ["assignees", "reviewers", "followers"];

watch(
  () => request.category_id,
  (categoryId) => {
    if (!categoryId) return;

    let wereParticipantsFiltered = false;

    const updateParticipants = (key: Participants) => {
      const filtered = filterParticipants(request[key], categoryId);

      if (filtered.length < request[key].length) {
        wereParticipantsFiltered = true;
        request[key] = filtered;
      }
    };

    participants.forEach(updateParticipants);

    if (wereParticipantsFiltered) {
      $notifyWarning(t("requests.removed_some_participants"), {
        duration: 10000,
      });
    }
  },
);
const handleRemove = (
  key: "assignees" | "reviewers" | "followers",
  item: UserUid,
  editProps: FieldProps,
) => {
  const newValue = request[key].filter((u) => u.user_id !== item.user_id);
  request[key] = newValue;
  editProps.veeField.onChange(newValue);
};

const handleUpdate = ({ field, value }: { field: string; value: any }) => {
  resetError(field);
  set(request, field, value);
};

const isSubmitting = ref(false);
const handleSubmit = (closeAfterSubmit: boolean) => {
  const form = unref(formRef);
  if (!form) return;

  resetErrors();

  form.validate().then((isValid: boolean) => {
    if (!isValid) return;

    if (request.category_id) {
      insightTrack(RoomTasksCreationEvent.CategorySelected);
    }
    if (!isEqual(request.description, emptyRequest.description)) {
      insightTrack(RoomTasksCreationEvent.DescriptionFilled);
    }
    if (
      request.attachments.documents.length ||
      request.attachments.folders.length
    ) {
      insightTrack(RoomTasksCreationEvent.DocumentsAttached);
    }
    if (request.priority !== emptyRequest.priority) {
      insightTrack(RoomTasksCreationEvent.PrioritySet);
    }
    if (request.status_id !== emptyRequest.status_id) {
      insightTrack(RoomTasksCreationEvent.StatusSet);
    }
    if (request.start_date) {
      insightTrack(RoomTasksCreationEvent.StartDateSet);
    }
    if (request.due_date) {
      insightTrack(RoomTasksCreationEvent.DueDateSet);
    }
    if (request.labels.length) {
      insightTrack(RoomTasksCreationEvent.LabelsSet);
    }
    if (request.assignees.length) {
      insightTrack(RoomTasksCreationEvent.AssigneesSet);
    }
    if (request.reviewers.length) {
      insightTrack(RoomTasksCreationEvent.ReviewersSet);
    }
    if (request.followers.length) {
      insightTrack(RoomTasksCreationEvent.FollowersSet);
    }

    const { attachments, ...rest } = cloneDeep(request);
    const custom_data = {
      ...rest.custom_data,
    };
    serializeCustomData(custom_data, customFields.value);

    const payload: CreateTaskPayload = {
      ...rest,
      custom_data: allowCustomFields ? custom_data : undefined,
      folders: attachments.folders,
      documents: attachments.documents,
    };

    isSubmitting.value = true;
    hookFormSubmitPromise(
      tasksStore
        .createTaskV2(payload)
        .then((task) => {
          const title =
            task.title.length > 40
              ? `${task.title.slice(0, 40)}...`
              : `${task.title}`;

          $notifySuccess(t("requests.request_has_been_created", { title }), {
            onClick: () => taskService.openTaskDetails(task.key),
          });

          clearForm(!closeAfterSubmit);

          if (closeAfterSubmit) {
            insightTrack(RoomTasksCreationEvent.TaskCreated);
            close();
          } else {
            insightTrack(RoomTasksCreationEvent.TaskCreatedAdd);
            titleInputRef.value?.focus();
          }
        })
        .finally(() => {
          isSubmitting.value = false;
        }),
    );
  });
};

export interface OpenParams {
  categoryId?: number;
  copyFromRequestId?: number | string;
  order?: number;
  attachDocumentIds?: number[];
  attachDocumentUids?: DocumentUid[];
  attachFolderIds?: number[];
  attachFolderUids?: FolderUid[];
}
const open = (params?: OpenParams) => {
  if (params?.categoryId) {
    request.category_id = params.categoryId;
  } else {
    const matches = location.hash.match(/\/tasks\/list\/(\d+)\/overview/);
    if (matches) request.category_id = parseInt(matches[1]);
  }

  if (params?.copyFromRequestId) {
    const copyFromTask =
      typeof params.copyFromRequestId === "string" // uid in this case
        ? tasksStore.tasksByUid[params.copyFromRequestId] ||
          tasksArchivedStore.tasksByUid[params.copyFromRequestId]
        : tasksStore.tasks[params.copyFromRequestId] ||
          tasksArchivedStore.tasks[params.copyFromRequestId];

    if (!copyFromTask) return;

    //request.copy_task_id = copyFromRequestId;
    Object.keys(emptyRequest).forEach((key) => {
      if (key === "copy_task_id") return;

      if (key === "attachments") {
        const folderUids = copyFromTask.folders.map((f) => ({
          folder_id: documentsStore.folderByIdMap[f.folder_id].uid,
        }));

        const documentUids = copyFromTask.documents.map((d) => ({
          document_id: documentsStore.fileByIdMap[d.document_id].uid,
        }));

        request.attachments = {
          folders: folderUids as { folder_id: string }[],
          documents: documentUids as { document_id: string }[],
        };

        return;
      }

      if (participants.includes(key as Participants)) {
        const participants = copyFromTask[key as Participants];
        if (!participants) return;

        (request as any)[key] = participants.reduce<UserUid[]>(
          (acc, { user_id }) => {
            const user = DrStore.state.room.members.members[user_id];
            if (!user) return acc;

            acc.push({
              user_id: user.uid,
            });

            return acc;
          },
          [],
        );

        return;
      }

      (request as any)[key] = (copyFromTask as any)[key];
    });
  }

  if (params?.order) {
    request.order = params.order;
  }

  if (params?.attachDocumentIds) {
    request.attachments.documents = params.attachDocumentIds.map((id) => ({
      document_id: documentsStore.fileByIdMap[id].uid,
    }));
  }

  if (params?.attachDocumentUids) {
    request.attachments.documents = request.attachments.documents.concat(
      params.attachDocumentUids,
    );
  }

  if (params?.attachFolderIds) {
    request.attachments.folders = params.attachFolderIds.map((id) => ({
      folder_id: documentsStore.folderByIdMap[id].uid,
    }));
  }

  if (params?.attachFolderUids) {
    request.attachments.folders = request.attachments.folders.concat(
      params.attachFolderUids,
    );
  }

  isDrawerVisible.value = true;
};

const clearForm = (rememberSelectedCategory?: boolean) => {
  Object.assign(request, cloneDeep(emptyRequest), {
    category_id: rememberSelectedCategory
      ? request.category_id
      : emptyRequest.category_id,
  });

  formRef.value?.reset(request);
  resetErrors();
};

const close = () => {
  clearForm();
  isDrawerVisible.value = false;
};

watch(isDrawerVisible, () => {
  if (isDrawerVisible.value) {
    setTimeout(() => titleInputRef.value?.focus(), 350);
    insightTrack(RoomTasksCreationEvent.ModalOpened);
  }
});

defineExpose({ open, close });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.drawer {
  width: 600px;
}
</style>
