<template>
  <DrDrawer
    title=""
    header-white
    no-footer
    size="custom"
    :shown="shown"
    :is-submitting="!member"
    @close="closeDrawer"
  >
    <template #sub-header>
      <div :class="$style.header" v-if="member">
        <DrAvatar
          size="lg"
          :identifier="member.email"
          :name="member.name"
          :url="member.avatar.reduced"
        />
        <h3 :class="$style.name">{{ member.name }}</h3>
        <div v-if="headline" :class="$style.headline">
          {{ headline }}
        </div>
        <DrTruncatedTextTooltip v-if="member" :content="member.email">
          <a
            :href="`mailto:${member.email}`"
            :class="$style.email"
            target="_blank"
          >
            {{ member.email }}
          </a>
        </DrTruncatedTextTooltip>
      </div>
    </template>

    <template #default>
      <div :class="$style.container">
        <h4 :class="$style.detailsTitle">{{ $t("shared.details") }}</h4>
        <div :class="$style.detailsSection">
          <div>{{ $t("form.office_number_label") }}</div>
          <div>{{ member?.office_number ?? "-" }}</div>
        </div>

        <div :class="$style.detailsSection">
          <div>{{ $t("form.title_label") }}</div>
          <div>{{ member?.title ?? "-" }}</div>
        </div>

        <div :class="$style.detailsSection">
          <div>{{ $t("form.company_label") }}</div>
          <div>{{ member?.company ?? "-" }}</div>
        </div>

        <div :class="$style.detailsSection">
          <div>{{ $t("shared.group") }}</div>
          <div>{{ member?.pgroup.name ?? "-" }}</div>
        </div>
      </div>
    </template>
  </DrDrawer>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import DrStore from "@drVue/store";

import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";

interface Props {
  memberId?: RoomMember["id"];
  shown: boolean;
}

interface Emits {
  (event: "close"): void;
}

const { memberId, shown } = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useI18n();

const member = computed(() =>
  memberId ? DrStore.state.room.members.members[memberId] : null,
);

const headline = computed(() => {
  if (!member.value) {
    return "";
  }

  return t("permissions.members.details_headline", {
    member: member.value.title,
    company: member.value.company,
  });
});

const closeDrawer = () => {
  emit("close");
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

$panelWidth: 280px;

.header {
  display: flex;
  flex-direction: column;
  gap: spacing.$xxs;
  width: $panelWidth;
  overflow: hidden;
}

.name {
  margin: 0;
  padding-top: 10px;
  font: typography.$headline_6_semibold;
  color: colors.$pr-900;
}

.headline {
  font: typography.$caption_regular;
  color: colors.$pr-500;
}

.email {
  font: typography.$caption_medium;
  font-weight: 400;

  &,
  &:hover,
  &:focus,
  &:visited {
    color: colors.$sc-600;
  }
}

.container {
  width: $panelWidth;
}

.detailsTitle {
  margin: 0;
  padding-bottom: spacing.$xl;
  font: typography.$subtitle_semibold;
  color: colors.$pr-900;
}

.detailsSection {
  & + & {
    margin-top: spacing.$l;
  }

  div:first-child {
    font: typography.$caption_regular;
    color: colors.$pr-600;
    margin-bottom: spacing.$xxs;
  }

  div:last-child {
    font: typography.$body_regular;
    color: colors.$pr-900;
  }
}
</style>
