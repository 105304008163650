<template>
  <div>
    <div :class="$style.header">
      <div :class="$style.title">{{ t("shared.comments") }}</div>
      <ElCheckbox
        v-if="isTaskManager"
        v-model="isActivityShown"
        :label="t('comments.show_activity')"
        :class="$style.checkboxInverse"
      />
    </div>

    <DrComments
      :items="comments"
      :last-visit-date="lastVisitDate"
      :user-id="currentUserId"
      :viewers-groups="viewersGroups"
      :user-group-id="currentUserGroupId"
      :default-viewers-groups="defaultViewersGroups"
      :has-manager-access="hasManagerAccess"
      :mentions="mentions"
      :is-pending="isPending"
      :is-error="isCommentsLoadError"
      :is-read-only="viewOnly"
      free-height
      condensed
      @retry="load"
      @add="addComment"
      @edit="editComment"
    >
      <template #item="{ item, startEdit }">
        <DrCommentsItem
          :item="item"
          :user-id="currentUserId"
          :viewers-groups="viewersGroups"
          :user-group-id="currentUserGroupId"
          :has-manager-access="ROOM_MEMBER_DATA.group.is_administrator"
          :mentions="mentions"
          @approve="approveComment"
          @remove="removeComment"
          @edit="startEdit($event), resetEditedCommentFilesAndFolders($event)"
        >
          <template v-if="item.activity" #body>
            <TaskActivityItem :activity="item.activity" />
          </template>

          <template #footer>
            <div :class="$style.attachments">
              <a
                v-for="attachment in item.attachments"
                :key="attachment.uid"
                :class="$style.attachment"
                :href="attachment.url"
                target="_blank"
              >
                <i
                  v-if="attachment.type === 'file'"
                  class="mimetypes"
                  :class="[
                    $style.icon,
                    $style.mimetypeIcon,
                    getIconClass(attachment.mimetype),
                  ]"
                />
                <DrIcon
                  v-else-if="attachment.type === 'folder'"
                  size="sm"
                  name="folder"
                  :class="$style.icon"
                />

                <DrTruncatedTextTooltip :content="attachment.name">
                  <span :class="$style.fileName">{{ attachment.name }}</span>
                </DrTruncatedTextTooltip>
              </a>
            </div>
          </template>
        </DrCommentsItem>
      </template>

      <template #composer-extra-actions>
        <TaskCommentsAttachmentComposer
          :file-uids="editedCommentFileUids"
          :folder-uids="editedCommentFolderUids"
          @update="handleUpdateAttachmentsInComposer"
        >
          <template #attachments="{ attachments }">
            <div :class="$style.attachments">
              <div
                v-for="attachment in attachments"
                :key="attachment.uid"
                :class="$style.attachment"
              >
                <i
                  v-if="attachment.type === 'file'"
                  class="mimetypes"
                  :class="[
                    $style.icon,
                    $style.mimetypeIcon,
                    getIconClass(attachment.mimetype),
                  ]"
                />
                <DrIcon
                  v-else-if="attachment.type === 'folder'"
                  size="sm"
                  name="folder"
                  :class="$style.icon"
                />

                <DrTruncatedTextTooltip :content="attachment.name">
                  <span :class="$style.fileName">{{ attachment.name }}</span>
                </DrTruncatedTextTooltip>

                <div
                  :class="$style.action"
                  @click="handleRemoveAttachmentItemInComposer(attachment.uid)"
                >
                  <DrIcon size="sm" name="cross" :class="$style.icon" />
                </div>
              </div>
            </div>
          </template>
        </TaskCommentsAttachmentComposer>
      </template>
    </DrComments>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { DrComments, DrCommentsItem } from "@shared/ui/dr-comments";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";
import { watchOnce } from "@vueuse/core";

import { ROOM_DATA, ROOM_MEMBER_DATA, USER_DATA } from "@setups/data";
import {
  documentViewUrl,
  folderUrl,
  memberUrl,
  taskUrl,
} from "@setups/room-urls";
import getIconClass from "@app/common/mimetype";
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { useDocumentsStore } from "@app/vue/store/pinia/room/documents/documents";
import { $notifyDanger } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTaskComments } from "../use-task-comments";
import TaskActivityItem from "./TaskActivityItem.vue";
import TaskCommentsAttachmentComposer from "./TaskCommentsAttachmentComposer.vue";

import type {
  TaskComment,
  TaskCommentCreatePayload,
  TaskCommentUpdatePayload,
} from "../use-task-comments";
import type {
  Document,
  Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type {
  Comment,
  CommentDraft,
  CommentUpdate,
  CommentViewersGroup,
} from "@shared/ui/dr-comments/types";
import type { WatchStopHandle } from "vue";

interface Props {
  taskId: TaskComment["task_id"];
  viewOnly: boolean;
}

const props = defineProps<Props>();

const { t } = useI18n();

type TrackCommentPayload = {
  attachment: "added" | "none";
  group: "public" | "my_group" | "individual";
  mentions: "tasks" | "users" | "tasks_&_users" | "none";
};

const FILE_UID_PREFIX = "doc_";
const FOLDER_UID_PREFIX = "fldr_";

const isTaskManager = ROOM_DATA.userPermissions.canManageTasks;
const tasksStore = useTasksStore(pinia);
const documentsStore = useDocumentsStore();

const {
  list,
  isCommentsLoadError,
  isActivitiesLoadError,
  isLoading,
  load,
  create,
  approve,
  remove,
  update,
  showActivity,
  setActivityRefreshTrigger,
} = useTaskComments(computed(() => props.taskId));

watchEffect(() => {
  if (isCommentsLoadError.value) {
    $notifyDanger(t("requests.failed_to_get_comments"));
  }
});
watchEffect(() => {
  if (isActivitiesLoadError.value) {
    $notifyDanger(t("requests.failed_to_get_activities"));
  }
});

const isProcessing = ref(false);
const isPending = computed(() => isLoading.value || isProcessing.value);

const currentUserId = computed(() => `${USER_DATA.id}`);
const hasManagerAccess = computed(
  () => ROOM_DATA.userPermissions.canManageTasks,
);

const isActivityShown = ref(false);
watchEffect(() => {
  if (isActivityShown.value) {
    insightTrack(TaskDetailsTrackingEvent.ActivityShown);
  }
  showActivity(isActivityShown.value);
});

const task = computed(() => tasksStore.tasks[props.taskId] || null);

let stopWatchTrigger: WatchStopHandle | undefined = undefined;
watchOnce(
  () => isActivityShown.value,
  () => (stopWatchTrigger = setActivityRefreshTrigger(task)),
);

onBeforeUnmount(() => {
  if (stopWatchTrigger) stopWatchTrigger();
});

const lastVisitDate = computed(() =>
  task.value ? task.value.last_visit_date : undefined,
);

const viewersGroups = computed<CommentViewersGroup[]>(() => {
  const { needCommentsApprove } = ROOM_DATA.userPermissions;

  return DrStore.state.room.groups.pgroupsList.reduce<CommentViewersGroup[]>(
    (groups, group) => {
      if (!group.symmetrical_view) {
        return groups;
      }

      if (
        group.is_administrator ||
        !needCommentsApprove ||
        (needCommentsApprove && group.id === ROOM_DATA.userPermissions.id)
      ) {
        groups.push({
          id: `${group.id}`,
          name: group.name,
        });
      }
      return groups;
    },
    [],
  );
});

const currentUserGroupId = computed(() => `${ROOM_DATA.userPermissions.id}`);

const defaultViewersGroups = computed(() => {
  return ROOM_DATA.userPermissions.defaultComments
    ? []
    : [currentUserGroupId.value];
});

const mentions = computed(() => ({
  tasks: tasksStore.tasksList.map((task) => ({
    ...task,
    url: taskUrl(ROOM_DATA.url, task.key),
  })),
  users: DrStore.state.room.members.membersList.map((member) => ({
    ...member,
    url: memberUrl(ROOM_DATA.url, member.id),
  })),
}));

const comments = computed(() => {
  return list.value.map((item) => {
    if ("date_added" in item) {
      const commentAuthor = {
        id: item.sender,
        name: t("comments.anonymous"),
        avatar: { thumbnail: "" },
        url: "",
      };

      const sender = DrStore.state.room.members.members[item.sender];
      if (sender && !sender.is_archived) {
        commentAuthor.id = sender.id;
        commentAuthor.name = sender.name;
        commentAuthor.avatar.thumbnail = sender.avatar.thumbnail;
        commentAuthor.url = memberUrl(ROOM_DATA.url, sender.id);
      }

      const approved_by = item.approved_by ? `${item.approved_by}` : undefined;

      return {
        id: `${item.id}`,
        body: item.body,
        date_added: item.date_added,
        date_modified: item.date_modified,
        author: {
          id: `${commentAuthor.id}`,
          name: commentAuthor.name,
          avatar: commentAuthor.avatar.thumbnail,
          group_id: `${item.group_id}`,
          url: commentAuthor.url,
        },
        viewers: item.viewers.map((id) => `${id}`),
        need_approve: item.need_approve,
        is_public: item.is_public,
        approved_by,
        attachments: [
          ...item.folders.map(({ folder_id }) => {
            const folder = documentsStore.folderByIdMap[folder_id];
            return {
              ...folder,
              date_added: folder.date_created,
              mimetype: undefined, // type safety in template
              url: folderUrl(ROOM_DATA.url, folder_id),
            };
          }),
          ...item.documents.map(({ document_id }) => {
            const file = documentsStore.fileByIdMap[document_id];
            return {
              ...file,
              date_added: file.date_created,
              url: documentViewUrl(ROOM_DATA.url, document_id),
            };
          }),
        ].sort((a, b) => a.treePosition - b.treePosition),
      };
    } else {
      const actorUser = {
        id: `${item.actor || 0}`,
        name: t("shared.unknown"),
        avatar: { thumbnail: "" },
      };

      if (item.actor) {
        const user = DrStore.state.room.members.members[item.actor];
        if (user && !user.is_archived) {
          actorUser.id = `${user.id}`;
          actorUser.name = user.name;
          actorUser.avatar.thumbnail = user.avatar.thumbnail;
        }
      }

      return {
        id: `${item.id}`,
        readonly: true,
        body: null,
        activity: item,
        date_added: item.timestamp,
        author: {
          id: `${actorUser.id}`,
          name: actorUser.name,
          avatar: actorUser.avatar.thumbnail,
        },
        is_public: true,
      };
    }
  });
});

type FileUid = string;
type FolderUid = string;

type UpdatePayload = {
  fileUids: FileUid[];
  folderUids: FolderUid[];
};

const editedCommentFileUids = ref<FileUid[]>([]);
const editedCommentFolderUids = ref<FolderUid[]>([]);

const resetEditedCommentFilesAndFolders = (commentId?: Comment["id"]) => {
  if (commentId) {
    const comment = comments.value.find((comment) => comment.id === commentId);
    const commentAttachmentsUids =
      comment?.attachments?.map((item) => item.uid) ?? [];

    editedCommentFileUids.value = commentAttachmentsUids.filter((uid) =>
      uid.startsWith(FILE_UID_PREFIX),
    );
    editedCommentFolderUids.value = commentAttachmentsUids.filter((uid) =>
      uid.startsWith(FOLDER_UID_PREFIX),
    );
  } else {
    editedCommentFileUids.value = [];
    editedCommentFolderUids.value = [];
  }
};

const handleRemoveAttachmentItemInComposer = (removeUid: string) => {
  if (removeUid.startsWith(FILE_UID_PREFIX)) {
    editedCommentFileUids.value = editedCommentFileUids.value.filter(
      (uid) => uid !== removeUid,
    );
  }
  if (removeUid.startsWith(FOLDER_UID_PREFIX)) {
    editedCommentFolderUids.value = editedCommentFolderUids.value.filter(
      (uid) => uid !== removeUid,
    );
  }
};

const getAttachmentPayload = () => {
  const payload: Pick<TaskCommentCreatePayload, "folders" | "documents"> = {
    documents: [],
    folders: [],
  };

  if (editedCommentFolderUids.value.length) {
    editedCommentFolderUids.value.forEach((folderUId) => {
      const folder = documentsStore.folderByUidMap[folderUId];
      payload.folders!.push({
        folder_id: folder.id,
        date_added: folder.date_created,
      });
    });
  }

  if (editedCommentFileUids.value.length) {
    editedCommentFileUids.value.forEach((fileUId) => {
      const file = documentsStore.fileByUidMap[fileUId];
      payload.documents!.push({
        document_id: file.id,
        date_added: file.date_created,
      });
    });
  }

  return payload;
};

const getTrackPayload = (comment: CommentDraft) => {
  let group: TrackCommentPayload["group"] = comment.is_public
    ? "public"
    : "individual";
  if (
    !comment.is_public &&
    comment.viewers.length === 1 &&
    comment.viewers[0] === currentUserGroupId.value
  ) {
    group = "my_group";
  }

  let mentions: TrackCommentPayload["mentions"] = "none";
  const bodyString = JSON.stringify(comment.body);

  if (bodyString.includes(`"type":"dr_mention"`)) {
    const mentionUser = bodyString.includes(`"type":"user"`);
    const mentionTask = bodyString.includes(`"type":"task"`);

    if (mentionUser && mentionTask) {
      mentions = "tasks_&_users";
    } else if (mentionUser) {
      mentions = "users";
    } else if (mentionTask) {
      mentions = "tasks";
    }
  }

  const trackPayload: TrackCommentPayload = {
    attachment:
      editedCommentFolderUids.value.length || editedCommentFileUids.value.length
        ? "added"
        : "none",
    group,
    mentions,
  };

  return trackPayload;
};

const addComment = async (comment: CommentDraft) => {
  if (!comment.body) {
    $notifyDanger(t("comments.to_add_you_must_specify_text"));
    return;
  }

  isProcessing.value = true;

  try {
    const payload: TaskCommentCreatePayload = {
      is_public: comment.is_public,
      viewers: comment.viewers.map((id) => Number(id)),
      body: comment.body,
      ...getAttachmentPayload(),
    };

    await create(payload);
    insightTrack(
      TaskDetailsTrackingEvent.CommentAdded,
      getTrackPayload(comment),
    );
    resetEditedCommentFilesAndFolders();
  } catch (_err) {
    $notifyDanger(t("comments.failed_to_add"));
  }

  isProcessing.value = false;
};

const approveComment = async (id: Comment["id"]) => {
  isProcessing.value = true;
  try {
    await approve(Number(id), USER_DATA.id);
    insightTrack(TaskDetailsTrackingEvent.CommentApproved);
  } catch (_err) {
    $notifyDanger(t("comments.failed_to_approve"));
  }
  isProcessing.value = false;
};

const removeComment = async (id: Comment["id"]) => {
  isProcessing.value = true;
  try {
    await remove(Number(id));
    insightTrack(TaskDetailsTrackingEvent.CommentRemoved);
  } catch (_err) {
    $notifyDanger(t("comments.failed_to_delete"));
  }
  isProcessing.value = false;
};

const editComment = async (comment: CommentUpdate) => {
  if (!comment.body) {
    $notifyDanger(t("comments.to_change_you_must_specify_text"));
    return;
  }

  isProcessing.value = true;

  try {
    const payload: TaskCommentUpdatePayload = {
      is_public: !!comment.is_public,
      viewers: comment.viewers.map((id) => Number(id)),
      body: comment.body,
      ...getAttachmentPayload(),
    };
    await update(Number(comment.id), payload);
    insightTrack(
      TaskDetailsTrackingEvent.CommentUpdated,
      getTrackPayload(comment),
    );
    resetEditedCommentFilesAndFolders();
  } catch (_err) {
    $notifyDanger(t("comments.failed_to_change"));
  }

  isProcessing.value = false;
};

const handleUpdateAttachmentsInComposer = (update: UpdatePayload) => {
  editedCommentFileUids.value = update.fileUids;
  editedCommentFolderUids.value = update.folderUids;
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing.$xxs;
}

.title {
  font: typography.$body_medium;
  font-weight: 500;
  color: colors.$pr-900;
}

.checkboxInverse:global(.el-checkbox) {
  flex-direction: row-reverse;
  gap: spacing.$xs;
  height: 24px;

  .el-checkbox__label {
    padding-left: 0;
  }
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.attachment {
  display: inline-grid;
  grid-template-columns: spacing.$m auto min-content;
  align-items: center;
  max-width: 192px;
  overflow: hidden;
  padding: spacing.$xxs 6px;
  gap: 6px;
  border-radius: 4px;
  background: colors.$pr-100;
}

.fileName {
  font: typography.$caption_regular;
  font-weight: 500;
  color: colors.$pr-800;
}

.icon {
  width: spacing.$m;
  color: colors.$pr-400;
}

.action {
  cursor: pointer;
}

.mimetypeIcon {
  scale: 0.8;
  place-self: center;
}
</style>
